<template>
  <main>
    <page-content page-slug="blog"></page-content>
    <article>
      <div class="bb-article-previews__container">
        <article-preview
          v-for="article in articles"
          :key="article._id"
          :articleExcerpt="article.excerpt == null ? '' : article.excerpt"
          :articleTitle="article.title"
          :articleSlug="article.title_slug"
          :articleAuthor="article.author == null ? '' : article.author.display"
          :articleModifiedTimestamp="article._modified"
          :articleImageId="article.image !== null ? article.image._id : ''"
        >
        </article-preview>
      </div>
      <div class="bb-navigation__container">
        <a
          href="#"
          v-if="skip >= entriesPerPage"
          class="bb-link bb-link--back"
          @click.prevent="pageBefore"
        >
          Neuere Artikel
        </a>
        <a
          href="#"
          v-if="skip + entriesPerPage < entries"
          class="bb-link bb-link--next"
          @click.prevent="nextPage"
        >
          Ältere Artikel
        </a>
      </div>
    </article>
  </main>
</template>
<script>
import ArticlePreview from "../components/ArticlePreview.vue";
import PageContent from "../components/PageContent.vue";

export default {
  components: { ArticlePreview, PageContent },
  props: {},
  data() {
    return {
      entries: 0,
      entriesPerPage: 5,
      skip: 0,
    };
  },
  created() {
    this.getArticles();
  },
  methods: {
    pageBefore() {
      this.skip = this.skip - this.entriesPerPage;
      this.getArticles();
      this.$scrollToAnchor('bb-app');
    },
    nextPage() {
      this.skip = this.skip + this.entriesPerPage;
      this.getArticles();
      this.$scrollToAnchor('bb-app');
    },
    getArticles() {
      this.axios
        .get(
          this.$cmsUrl + "/api/collections/get/article?filter[published]=1&limit=" +
            this.entriesPerPage +
            "&skip=" +
            this.skip
        )
        .then(
          function (response) {
            this.entries = response.data.total;
            this.$store.commit("setArticles", response.data.entries);
          }.bind(this)
        );
    },
  },
  computed: {
    articles() {
      return this.$store.state.articles;
    }
  },
};
</script>
