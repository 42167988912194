<template>
  <div id="bb-app">
    <div id="bb-logo-container" class="" @click="hamburgerOpen = false">
      <router-link to="/" :target="isLandingPage ? '_blank' : ''">
        <img v-if="logoUrl" class="bb-logo--fullsize" :src="logoUrl" alt="" />
        <img
          v-if="logoMobileUrl"
          class="bb-logo--small"
          :src="logoMobileUrl"
          alt=""
        />
      </router-link>
    </div>
    <div
      v-if="!isLandingPage"
      id="bb-nav__hamburger-container"
      @click="hamburgerOpen = !hamburgerOpen"
    >
      <div id="bb-nav__hamburger-label">Menü</div>
      <div
        id="bb-nav__hamburger-button"
        :class="{ 'bb-nav--open': hamburgerOpen }"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <nav-bar
      v-if="$router.currentRoute.path != '/'"
      :pages="navPages"
      :hamburger-open="hamburgerOpen"
      :nav-on-top="true"
      @clicked="hamburgerOpen = false"
    ></nav-bar>
    <router-view />
    <nav-bar
      v-if="$router.currentRoute.path == '/'"
      :pages="navPages"
      :hamburger-open="hamburgerOpen"
      :nav-on-top="false"
      @clicked="hamburgerOpen = false"
    ></nav-bar>
    <footer id="bb-footer" :class="{'bb-footer--minimal' : isLandingPage}">
      <div id="bb-footer__intro" v-if="!isLandingPage">
        <h2>
          {{ contactFormTitle }}
        </h2>
        <div>
          {{ contactFormText }}
        </div>
      </div>
      <contact-form
        v-if="!isLandingPage"
        :error-message="contactFormErrorMessage"
        :success-message="contactFormSuccessMessage"
        :inputs="contactFormFields"
      ></contact-form>
      <div id="bb-footer__legal">
        <router-link
          v-for="footerPage in footerPages"
          :key="footerPage._id"
          :to="footerPage.title_slug"
          class="bb-footer__link"
          >{{ footerPage.title }}</router-link
        >
      </div>
    </footer>
    <cookie-consent v-if="!$cookies.isKey('bb-mandatory-cookies-accept')"></cookie-consent>
  </div>
</template>
<script>
import CookieConsent from "./components/CookieConsent";
import NavBar from "./components/NavBar";
import ContactForm from "./components/ContactForm";

export default {
  components: { NavBar, ContactForm, CookieConsent },
  data: function () {
    return {
      logoUrl: "",
      logoMobileUrl: "",
      contactFormTitle: "",
      contactFormText: "",
      contactFormErrorMessage: "",
      contactFormSuccessMessage: "",
      contactFormFields: [],
      hamburgerOpen: false,
    };
  },
  beforeCreate: function () {
    this.axios.get(this.$cmsUrl + "/api/collections/get/page").then(
      function (response) {
        this.$store.commit("setPages", response.data.entries);
      }.bind(this)
    );
    this.axios.get(this.$cmsUrl + "/api/collections/get/block").then(
      function (response) {
        this.$store.commit("setBlocks", response.data.entries);
      }.bind(this)
    );
    this.axios.get(this.$cmsUrl + "/api/collections/get/panel").then(
      function (response) {
        this.$store.commit("setPanels", response.data.entries);
      }.bind(this)
    );
    this.axios.get(this.$cmsUrl + "/api/singletons/get/logo").then(
      function (response) {
        this.logoUrl = this.$cmsUrl + "/" + response.data.image.path;
        this.logoMobileUrl =
          this.$cmsUrl + "/" + response.data.image_small.path;
      }.bind(this)
    );
    this.axios.get(this.$cmsUrl + "/api/singletons/get/contactform").then(
      function (response) {
        this.contactFormTitle = response.data.title;
        this.contactFormText = response.data.text;
        this.contactFormSuccessMessage = response.data.successMessage;
        this.contactFormErrorMessage = response.data.errorMessage;
        this.contactFormFields = response.data.fields;
      }.bind(this)
    );

  },
  mounted() {
    //this.$store.commit('setLoaded')
  },
  computed: {
    navPages() {
      return this.isLandingPage
        ? []
        : this.pages.filter((page) => page.nav == true);
    },
    footerPages() {
      return this.pages.filter((page) => page.footer == true);
    },
    pages() {
      return this.$store.state.pages;
    },
    loading() {
      return this.$store.state.loading;
    },
    isLandingPage() {
      return this.$store.state.isLandingPage;
    }
  },
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
@import "@/scss/_basic.scss";

#bb-footer {
  &.bb-footer--minimal {
    min-height: unset;
    padding-top: 0px;
  }

  &__intro {
    max-width: $content-width-m;
    margin: auto;
    margin-bottom: 50px;

    h2 {
      font-size: 2.5rem;
      border: none;
      padding: inherit;
      text-align: center;
    }
  }

  &__legal {
    margin: 50px;
    display: flex;
    justify-content: center;

    a ~ a {
      margin-left: 20px;
    }
  }
}

#bb-logo-container {
  z-index: 20;
  position: fixed;
  top: 0px;
  left: 0px;
  height: $logo-height-mini;
  width: $logo-width-mini;
  display: flex;
  align-items: center;
  padding-left: $logo-left-padding;
  transition: height 0.3s;

  &.bb-logo-container--fullsize {
    height: $logo-height;
    width: $logo-width;

    .bb-logo {
      &--fullsize {
        display: block;
      }
      &--small {
        display: none;
      }
    }
  }

  @media (min-width: $breakpoint-m) {
    height: $logo-height;
    width: $logo-width;
  }

  .bb-logo {
    &--fullsize {
      display: none;

      @media (min-width: $breakpoint-m) {
        display: block;
      }
    }
    &--small {
      display: block;
      height: $logo-height-mini;
      width: $logo-width-mini;

      @media (min-width: $breakpoint-m) {
        display: none;
      }
    }
  }

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}

#bb-nav__hamburger {
  &-button {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    -webkit-user-select: none;
    user-select: none;
    &.bb-nav--open {
      span {
        opacity: 1;
        transform: rotate(-45deg) translate(-1px, -1px);
        background: #232323;

        &:nth-last-child(2) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        &:nth-last-child(3) {
          transform: rotate(45deg) translate(0, 0px);
        }
      }
    }

    span {
      display: block;
      width: 33px;
      height: 2px;
      margin-bottom: 8px;
      position: relative;

      background: #000000;
      border-radius: 3px;

      z-index: 1;

      transform-origin: 4px 0px;

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

      &:first-child {
        transform-origin: 0% 0%;
      }

      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }
  }

  &-label {
    position: absolute;
    top: 20px;
    right: 70px;
  }

  &-container {
    display: none;
    position: sticky;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 10;
    height: $logo-height-mini;
    background-color: $primary-nav-color;
    backdrop-filter: blur(5px);

    @media (max-width: $breakpoint-m) {
      display: block;
    }
  }
}
</style>
