import Vue from 'vue'
import VueRouter from 'vue-router'
import PageView from '../views/PageView.vue'
import BlogView from '../views/BlogView.vue'
import ArticleView from '../views/ArticleView.vue'
import GoodieListView from '../views/GoodieListView.vue'
import GoodieView from '../views/GoodieView.vue'
import IdeaView from '../views/IdeaView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: PageView,
    props: {
      pageSlug: 'startseite'
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogView
  },
  {
    path: '/blog/:articleSlug',
    name: 'article',
    component: ArticleView,
    props: true
  },
  {
    path: '/kostenlose-vorlagen',
    name: 'goodies',
    component: GoodieListView
  },
  {
    path: '/kostenlose-vorlagen/:goodieSlug',
    name: 'goodie',
    component: GoodieView,
    props: true
  },
  {
    path: '/ideen',
    name: 'ideas',
    component: IdeaView
  },
  {
    path: '/:pageSlug',
    name: 'Page',
    component: PageView,
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router