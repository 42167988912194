<template>
  <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="#000000" :width="width" :height="height">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <g fill="none" fill-rule="evenodd" transform="translate(4 5)">
        <g stroke="#000000" stroke-linecap="round" stroke-linejoin="round">
          <path d="m11.5 5.5h-7"></path>
          <path d="m11.5 9.5h-7"></path>
          <path d="m11.5 1.5h-7"></path>
        </g>
        <path
          d="m1.49884033 2.5c.5 0 1-.5 1-1s-.5-1-1-1-.99884033.5-.99884033 1 .49884033 1 .99884033 1zm0 4c.5 0 1-.5 1-1s-.5-1-1-1-.99884033.5-.99884033 1 .49884033 1 .99884033 1zm0 4c.5 0 1-.5 1-1s-.5-1-1-1-.99884033.5-.99884033 1 .49884033 1 .99884033 1z"
          fill="#000000"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>