<template>
  <main class="bb-landingpage">
    <section id="bb-goodie__detail">
      <h2 class="bb-goodie__welcome">Jetzt Kostenlose Vorlage anfordern!</h2>
      <div class="bb-goodie__container">
        <div class="bb-goodie__form">
          <div>
            Bitte gib Deine E-Mail Adresse ein, und wir senden Dir diese Vorlage
            kostenlos zu!
          </div>
          <form
            v-if="goodie != undefined"
            id="bb-goodieform"
            @submit.prevent="checkAndSubmit()"
          >
            <div class="bb-input__container">
              <input
                name="firstname"
                type="text"
                id="bb-input--firstname"
                placeholder="Vorname"
              />
            </div>
            <input
              name="goodie"
              type="hidden"
              id="bb-input--goodie"
              :value="goodie._id"
            />
            <template v-for="input in formFields">
              <div :key="input.value.name_slug" class="bb-input__container">
                <input
                  :name="input.value.name_slug"
                  :type="input.value.type"
                  :id="'bb-goodie-input--' + input.value.name_slug"
                  :placeholder="input.value.name + ' *'"
                  :required="input.value.required"
                />
                <label :for="'bb-goodie-input--' + input.value.name_slug">{{
                  input.value.name
                }}</label>
              </div>
            </template>
            <div>
              <input
                class="bb-button--primary"
                type="submit"
                value="Jetzt zusenden!"
                v-if="!submitted"
              />
              <div v-else class="bb-button--primary" :class="submitClass">
                {{ resultMessage }}
              </div>
            </div>
          </form>
        </div>
        <div class="bb-goodie" v-if="goodie != undefined">
          <h3 class="bb-goodie__title">{{ goodie.title }}</h3>
          <div v-if="thumbId != ''" class="bb-goodie__thumb">
            <img :src="$getThumbnail(thumbId, 200)" />
          </div>
          <div class="bb-goodie__description" v-html="goodie.description"></div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  components: {},
  props: {
    goodieSlug: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      goodie: undefined,
      submitted: false,
      submitError: false,
      resultMessage: "",
      goodieFormSuccessMessage: "Sent!",
      goodieFormErrorMessage: "Not sent!",
      formFields: [],
    };
  },
  created() {
    this.$store.commit("setIsLandingPage");
    this.axios
      .get(
        this.$cmsUrl +
          "/api/collections/get/goodie?filter[title_slug]=" +
          this.goodieSlug
      )
      .then(
        function (response) {
          this.goodie = response.data.entries[0];
        }.bind(this)
      );
    this.axios.get(this.$cmsUrl + "/api/singletons/get/goodieform").then(
      function (response) {
        this.goodieFormSuccessMessage = response.data.successMessage;
        this.goodieFormErrorMessage = response.data.errorMessage;
        this.formFields = response.data.fields;
      }.bind(this)
    );
  },
  methods: {
    checkAndSubmit() {
      var formElement = document.getElementById("bb-goodieform");

      this.axios
        .post("/submit/goodierequest.php", new FormData(formElement), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          function (response) {
            console.log(response.data);
            this.submitted = true;
            this.resultMessage = this.goodieFormSuccessMessage;
          }.bind(this)
        )
        .catch((error) => {
          this.submitted = true;
          this.submitError = true;
          this.resultMessage = this.goodieFormErrorMessage;
          console.log(error);
        });
    },
  },
  computed: {
    thumbId() {
      return Object.prototype.hasOwnProperty.call(this.goodie, "thumb") &&
        this.goodie.thumb != null
        ? this.goodie.thumb._id
        : "";
    },
    submitClass() {
      if (this.submitted)
        return this.submitError
          ? "bb-highlight--error"
          : "bb-highlight--success";
      else return "";
    },
  },
};
</script>
<style lang="scss">
#bb-goodie__detail {
  .bb-goodie__welcome {
    text-align: center;
  }

  .bb-goodie__container {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 0px 10px 3px #0000000d;

    .bb-goodie__form {
      flex: 1;
      background-color: lightgray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 50px;

      .bb-input__container:first-of-type {
        position: absolute;
        top: -50px;
      }
    }

    .bb-goodie {
      flex: 1;
      padding: 50px;
    }
  }
}
</style>