<template>
  <main id="bb-ideas__page">
    <page-content page-slug="ideen"></page-content>
    <article>
      <div class="bb-idea-collection__list">
        <div
          v-for="ideaCollection in ideas"
          :key="ideaCollection._id"
          class="bb-idea-collection__container"
        >
          <h3 class="bb-idea-collection__title">{{ ideaCollection.title }}</h3>
          <div class="bb-idea-collection__subtitle">
            {{ ideaCollection.subtitle }}
          </div>
          <div class="bb-idea__list">
            <div
              v-for="idea in ideaCollection.idea"
              :key="idea.value.name"
              class="bb-idea__container"
            >
              <div class="bb-idea__title">{{ idea.value.name }}</div>
              <a
                :href="getIdeaLink(idea)"
                class="bb-idea__link bb-button--secondary"
                :target="getIdeaLinkTarget(idea)"
                >{{ getIdeaLinkText(idea) }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </article>
  </main>
</template>
<script>
import PageContent from "../components/PageContent.vue";

export default {
  components: { PageContent },
  props: {},
  data() {
    return {};
  },
  created() {
    this.axios
      .get(this.$cmsUrl + "/api/collections/get/ideas?populate=true")
      .then(
        function (response) {
          this.$store.commit("setIdeas", response.data.entries);
        }.bind(this)
      );
  },
  methods: {
    getIdeaLink(idea) {
      if (Object.prototype.hasOwnProperty.call(idea.value, "blogpost"))
        return "/blog/" + idea.value.blogpost.title_slug;
      if (Object.prototype.hasOwnProperty.call(idea.value, "goodie"))
        return "/kostenlose-vorlagen/" + idea.value.goodie.title_slug;
    },
    getIdeaLinkText(idea) {
      if (Object.prototype.hasOwnProperty.call(idea.value, "blogpost"))
        return "Zum Blogartikel";
      if (Object.prototype.hasOwnProperty.call(idea.value, "goodie"))
        return "Zur Vorlage";
    },
    getIdeaLinkTarget(idea) {
      if (Object.prototype.hasOwnProperty.call(idea.value, "goodie"))
        return "_blank";
      return "";
    },
  },
  computed: {
    ideas() {
      return this.$store.state.ideas;
    },
  },
};
</script>
<style lang="scss">
#bb-ideas__page {
  .bb-idea-collection__container {
    max-width: 500px;
    margin: auto;

    ~ .bb-idea-collection__container {
      margin-top: 150px;
    }

    .bb-idea-collection__title {
      text-transform: uppercase;
    }
  }

  .bb-idea__list {
    display: flex;
    flex-direction: column;

    .bb-idea__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;

      .bb-idea__title {
        margin: auto 0px;
        padding: 10px 10px 10px 0px;
        flex: 2;
        min-width: 200px;

        &::before {
          content: "✔";
          margin-right: 10px;
        }
      }

      .bb-idea__link {
        min-width: 150px;
        flex: 1;
        align-self: center;
        text-align: center;
      }
    }
  }
}
</style>