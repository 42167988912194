<template lang="pug">
  nav#bb-nav(:class="{ 'bb-nav--open': hamburgerOpen }")
    ul#bb-nav__menu(:class="{ 'bb-nav--top': logoAside }", @click="$emit('clicked')")
      li(v-for="(page, index) in pages", :key="page._id",:class="{'bb-button--primary': index == Object.keys(pages).length - 1, }")
        router-link(:to="'/' + page.title_slug") {{ page.title }}
</template>
<script>
export default {
  data() {
    return {
      navElement: undefined,
      logoAside: this.navOnTop,
    };
  },
  props: {
    pages: {
      type: Array,
      require: true,
    },
    hamburgerOpen: {
      type: Boolean,
      default: false,
    },
    navOnTop: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    updateScroll() {
      this.logoAside = this.navElement.offsetTop - window.pageYOffset <= 110;
    },
  },
  mounted() {
    this.navElement = document.getElementById("bb-nav");
    window.addEventListener("scroll", this.updateScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "@/scss/_basic.scss";

#bb-nav {
  position: sticky;
  top: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  justify-content: flex-end;
  color: $primary-font-color;
  flex: 1;
  flex-wrap: wrap;
  background-color: $primary-nav-color;
  backdrop-filter: blur(5px);
  z-index: 10;
  height: 110px;
  font-size: 1.3rem;
  box-shadow: 0px 0px 11px 0px #0000000d;

  @media (max-width: $breakpoint-m) {
    position: fixed;
    top: $logo-height-mini;
    bottom: 0px;
    height: unset;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    box-shadow: none;
    overflow-y: auto;
  }

  @media (min-width: $breakpoint-m) and (max-width: $breakpoint-xl) {
    font-size: 2.3vw;
  }

  &.bb-nav--open {
    transform: translate(0, 0);
  }
}

#bb-nav__menu {
  display: flex;
  list-style: none;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 0px;
  width: 100%;
  transition: margin-left 0.3s;
  margin-left: 0;

  &.bb-nav--top {
    @media (min-width: $breakpoint-m) {
      margin-left: $logo-width + $logo-left-padding;
    }
  }

  @media (max-width: $breakpoint-m) {
    flex-direction: column;
    justify-content: center;
    /*align-items: flex-start;*/
  }

  li {
    padding: 5px 0px;
    margin: 0px 20px;

    @media (max-width: $breakpoint-m) {
      margin: 10px 20px;
    }

    a {
      font-weight: normal;

      &:hover,
      .router-link-active {
        padding: 4px 0px;
        border-bottom: 1px solid black;

        &.bb-button--primary {
          border: none;
        }
      }
    }
  }
}
</style>
