<template lang="pug">
modal-window(:close-button="false", :showing-external="showingModal")
  h2(slot="header") Cookies akzeptieren
  div(slot="body")
    .bb-cookie-consent__container(v-if="showingDetails")
        .bb-input__container
            input(disabled checked="checked" name="bb-cookie-consent--mandatory" type="checkbox")
            label(for="bb-cookie-consent--mandatory") Technisch notwendige Cookies
        .bb-input__container
            input#bb-cookie-consent--google-analytics(v-model="acceptGoogleAnalytics" value="false" type="checkbox")
            label(for="bb-cookie-consent--google-analytics") Google Analytics
  div(slot="footer")
    .bb-cookie-consent__buttons
      button.bb-button--secondary(@click="details") {{showingDetails ? 'Auswahl speichern' : 'Auswahl anpassen'}}
      button.bb-button--primary(@click="acceptAll") Alle annehmen
</template>
<script>
import ModalWindow from "./ModalWindow";

export default {
  components: { ModalWindow },
  data() {
    return {
      showingModal: true,
      showingDetails: false,
      acceptGoogleAnalytics: false,
    };
  },
  methods: {
    acceptAll() {
      this.$cookies.set("bb-mandatory-cookies-accept", true);
      this.showingModal = false;
    },
    details() {
      if(!this.showingDetails) {
        this.showingDetails=true
      } else {
        this.$cookies.set("bb-mandatory-cookies-accept", true);
        this.showingModal = false;
      }
    },
  },
};
</script>

<style lang="scss">
.bb-cookie-consent__buttons {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  margin: 0px -3px;

  button {
    flex: 1;
    min-width: 200px;
    margin: 3px 3px;
  }
}
</style>
