<template>
  <definition-block
    v-if="block._id == '607687783035356e8a000109'"
    :panels="panels"
    :block="block"
  ></definition-block>
  <section :id="sectionId" class="bb-block" v-else>
    <h2>{{ block.title }}</h2>
    <div class="bb-block__container">
      <block-panel
        v-for="panel in panels"
        :key="panel._id"
        :panel="panel"
      ></block-panel>
    </div>
  </section>
</template>
<script>
import BlockPanel from "./BlockPanel.vue";
import DefinitionBlock from "../components/DefinitionBlock.vue";

export default {
  components: { BlockPanel, DefinitionBlock },
  props: {
    block: {
      require: true,
      type: Object,
    },
  },
  computed: {
    panels() {
      return this.allPanels.filter(
        (panel) => panel.block._id == this.block._id
      );
    },
    allPanels() {
      return this.$store.state.panels;
    },
    sectionId() {
      return "bb-block--" + this.block._id;
    },
  },
};
</script>
<style lang="scss">
@import '@/scss/_variables.scss';

.bb-block {
  
  h2 {
    text-align: center;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: auto;
  }
}

#bb-block {
  // Wie ich arbeite
  &--6076ec7d376164a3a8000369 {
    background-color: #e9ead4;
    max-width: unset;

    img {
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
  }

  // Customer Journey
  &--6076ea523236309ea2000246 {
    .bb-block__container {
      margin-top: 5rem;
    }

    .bb-panel {
      justify-content: space-between;
    }
  }

  // Über mich
  &--607e9ef6663133fc8c00005e {
    background-color: #e9ead4;
    max-width: unset;
    min-height: calc(100vh - #{$logo-height} - #{$site-padding} * 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bb-block__container {
      max-width: 1024px;
      margin: 0px;
    }

    .bb-panel {
      flex-direction: row-reverse;
      margin: 20px;
      max-width: unset;
      align-items: center;

      @media (max-width: $breakpoint-m) {
        flex-direction: column;
      }

      img {
        border-radius: 50%;
        margin-left: 50px;
        @media (max-width: $breakpoint-m) {
          margin-left: 0px;
        }
      }
    }
  }
}
</style>
