<template lang="pug">
<main>
  article.bb-article(v-if="article != undefined")
    img.bb-article__featured-image(
      v-if="imageUrl != ''",
      :src="imageUrl",
      alt=""
    )
    h2 {{ article.title }}
    .bb-article__meta(v-if="article.author") Geschrieben von <strong>{{ article.author.display }}</strong> am <em>{{ modifiedDate }}</em>
    .bb-article__content(v-html="article.content")
    .bb-article__actions
      .bb-article__action
        router-link(:to="'/blog'")
          icon-list(width="30" height="30")
          span Weitere Blogartikel
      sharing-bar(:title="article.title" :text="articleSlug" label="Artikel teilen")
      //-.bb-article-like__container
        //-a
          //-icon-heart(width="40", height="40")
</main>
</template>
<script>
import SharingBar from "../components/SharingBar.vue";
//import IconHeart from "../components/icons/IconHeart.vue";
import IconList from "../components/icons/IconList.vue";

export default {
  components: { SharingBar, IconList },
  props: {
    articleSlug: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      article: {},
    };
  },
  created() {
    this.axios
      .get(
        this.$cmsUrl +
          "/api/collections/get/article?filter[title_slug]=" +
          this.articleSlug
      )
      .then(
        function (response) {
          this.article = response.data.entries[0];
        }.bind(this)
      );
  },
  computed: {
    modifiedDate() {
      var d = new Date(this.article._modified * 1000);
      return d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();
    },
    imageUrl() {
      return Object.prototype.hasOwnProperty.call(this.article, "image") &&
        this.article.image != null
        ? this.$cmsUrl + "/storage/uploads" + this.article.image.path
        : "";
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/variables";

.bb-article {
  .bb-article {
    &__featured-image {
      height: 50vw;
      max-height: 500px;
      width: 100%;
      object-fit: cover;

      @media (max-width: $breakpoint-s) {
        width: 100vw;
        margin: 0px -50px;
        margin-top: -50px;
      }
    }

    &__actions {
      margin: 50px 0px;
      display: flex;
      justify-content: space-between;

      .bb-article__action {
        a {
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
