<template lang="pug">
  .bb-article-preview
    h3
      router-link(:to="{ name: 'article', params: { articleSlug: articleSlug } }") {{ articleTitle }}
    .bb-article__meta(v-if="articleAuthor != ''") Geschrieben von <strong>{{ articleAuthor }}</strong> am <em>{{ modifiedDate }}</em>
    .bb-article__content-preview
      img.bb-article__thumbnail(v-if="articleImageId" :src="$getThumbnail(articleImageId, 200, 200)" alt="")
      .bb-article__excerpt(v-html="articleExcerpt")
    router-link.bb-button--primary(:to="{ name: 'article', params: { articleSlug: articleSlug } }") Lesen
</template>
<script>
export default {
  props: {
    articleSlug: {
      type: String,
      required: true,
    },
    articleTitle: {
      type: String,
      required: true,
    },
    articleExcerpt: {
      type: String,
      required: true,
    },
    articleAuthor: {
      type: String,
      required: true,
    },
    articleModifiedTimestamp: {
      type: Number,
      required: true,
    },
    articleImageId: {
      type: String,
      required: true,
    },
  },
  computed: {
    modifiedDate() {
      var d = new Date(this.articleModifiedTimestamp * 1000);
      return d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();
    },
  },
};
</script>
<style lang="scss">
.bb-article-preview {
  margin-bottom: 100px;
}
</style>
