import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
      pages: [],
      blocks: [],
      panels: [],
      articles: [],
      goodies: [],
      ideas: [],
      loading: true,
      isLandingPage: false
    },
    mutations: {
      setPages(state, pages) {
        state.pages = pages
      },
      setBlocks(state, blocks) {
        state.blocks = blocks
      },
      setPanels(state, panels) {
        state.panels = panels
      },
      setArticles(state, articles) {
        state.articles = articles
      },
      setGoodies(state, goodies) {
        state.goodies = goodies
      },
      setIdeas(state, ideas) {
        state.ideas = ideas
      },
      setLoaded(state) {
        state.loading = false
      },
      setIsLandingPage(state, isLandingPage = true) {
        state.isLandingPage = isLandingPage
      }
    },
    getters: {
      getPageId: (state) => (pageTitle) => {
        return state.pages.find(page => page.title.toLowerCase().trim() == pageTitle.toLowerCase().trim());
      }
    }
  })

export default store