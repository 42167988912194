<template>
  <div class="bb-panel" :class="panelClass">
    <img v-if="panel.image != null" :src="imageSrc" />
    <h3 v-if="panel.title != ''">{{ panel.title }}</h3>
    <h4 v-if="panel.subtitle != ''">{{ panel.subtitle }}</h4>
    <div v-if="panel.text != ''" v-html="panel.text"></div>
    <router-link class="bb-button--primary" v-if="link !== false" :to="link">{{
      panel.link.text
    }}</router-link>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      imageSrc:
        this.panel.image != null
          ? this.$cmsUrl + "/" + this.panel.image.path
          : "",
    };
  },
  props: {
    panel: {
      require: true,
      type: Object,
    },
  },
  computed: {
    panelClass() {
      return "bb-panel--" + this.panel._id;
    },
    link() {
      var link = this.panel.link;
      return link != undefined && link.link != "" && link.link != null
        ? link.link
        : false;
    },
  },
};
</script>
<style lang="scss">
.bb-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 10px;
  margin-bottom: 50px;
  max-width: 350px;

  img {
    width: 250px;
  }

  ul {
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;

    li {
      padding-left: 1em;
      text-indent: -1em;

      &:before {
        content: "#";
        padding-right: 5px;
      }
    }
  }
}
</style>
