<template>
  <main id="bb-goodie__page">
    <page-content page-slug="kostenlose-vorlagen"></page-content>
    <article>
      <div class="bb-goodie__list">
        <goodie-preview
          v-for="goodie in goodies"
          :key="goodie._id"
          :goodie="goodie"
        >
        </goodie-preview>
      </div>
    </article>
  </main>
</template>
<script>
import GoodiePreview from "../components/GoodiePreview.vue";
import PageContent from "../components/PageContent.vue";

export default {
  components: { GoodiePreview, PageContent },
  props: {},
  data() {
    return {
      selectedGoodieId: undefined,
    };
  },
  created() {
    this.axios.get(this.$cmsUrl + "/api/collections/get/goodie").then(
      function (response) {
        this.$store.commit("setGoodies", response.data.entries);
      }.bind(this)
    );
  },
  methods: {
    openModal(goodieId) {
      this.selectedGoodieId = goodieId;
    },
  },
  computed: {
    goodies() {
      return this.$store.state.goodies;
    },
    selectedGoodieTitle() {
      return this.goodies.find((goodie) => goodie._id == this.selectedGoodieId)
        .title;
    },
  },
};
</script>
<style lang="scss">
#bb-goodie__page {
  form {
    width: 100%;
  }

  .bb-input__container {
    margin: 50px 0px;
  }

  .bb-goodie__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -25px;
  }
}
</style>