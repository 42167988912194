<template>
  <div class="bb-goodie__preview-container">
    <h3>
      {{ goodie.title }}
    </h3>
    <div class="bb-goodie__preview">
      <div v-if="thumbId != ''" class="bb-goodie__thumb">
        <img :src="$getThumbnail(thumbId, 100)" />
      </div>
      <div
        class="bb-goodie__description"
        v-html="goodie.excerpt == null ? '' : goodie.excerpt"
      ></div>
    </div>
    <router-link
      class="bb-goodie__action bb-button--primary"
      :to="{ name: 'goodie', params: { goodieSlug: goodie.title_slug } }"
      target="_blank"
    >
      Zur Vorlage
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    goodie: {
      type: Object,
      require: true,
    },
  },
  computed: {
    thumbId() {
      return Object.prototype.hasOwnProperty.call(this.goodie, "thumb") &&
        this.goodie.thumb != null
        ? this.goodie.thumb._id
        : "";
    },
  },
};
</script>
<style lang="scss">
.bb-goodie__preview-container {
  flex: 1;
  padding: 0px 25px;
  margin-bottom: 100px;
  min-width: 250px;

  .bb-goodie__preview {
    display: flex;

    .bb-goodie {
      &__thumb {
        flex: 2;
        display: flex;
        align-content: center;

        img {
          max-width: 100%;
          object-fit: contain;
        }

        ~ .bb-goodie__description {
          flex: 3;
          padding-left: 10px;
          display: flex;

          p {
            margin: auto;
          }
        }
      }
    }
  }

  .bb-goodie__action {
    margin-top: 1em;
  }
}
</style>