<template>
  <main>
    <page-content :page-slug="pageSlug"></page-content>
    <page-block
      v-for="block in blocks"
      :key="block._id"
      :block="block"
    ></page-block>
  </main>
</template>
<script>
import PageBlock from "../components/PageBlock.vue";
import PageContent from "../components/PageContent.vue";

export default {
  components: {
    PageBlock,
    PageContent,
  },
  data: function () {
    return {};
  },
  props: {
    pageSlug: {
      require: true,
      type: String,
    },
  },
  computed: {
    pageId() {
      var page = this.$store.state.pages.find(
        (page) => page.title_slug == this.pageSlug
      );

      return page == undefined ? undefined : page._id;
    },
    blocks() {
      return this.allBlocks.filter((block) => block.page._id == this.pageId);
    },
    allBlocks() {
      return this.$store.state.blocks;
    },
  },
};
</script>