<template lang="pug">
header.bb-header.bb-slider__container
  swiper.bb-header__slider(:options="swiperOptions")
    swiper-slide(v-for="panel in panels", :key="panel._id")
      .bb-definition__container
        .bb-definition__title {{ panel.title }}
        .bb-definition__subtitle {{ panel.subtitle }}
        .bb-definition__entry(v-html="panel.text")
    .swiper-pagination(slot="pagination")
    .swiper-button-prev(slot="button-prev")
    .swiper-button-next(slot="button-next")
  .bb-header__image(:style="{ 'background-image': `url(${backgroundImage})` }")
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    block: {
      type: Object,
      require: true,
    },
    panels: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        centeredSlides: true,
        speed: 800,
        autoplay: {
          delay: 4000,
          disableOnInteraction: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    backgroundImage() {
      return this.$getThumbnail(this.block.image._id, 2000);
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
@import "@/scss/_basic.scss";

.bb-header {
  z-index: 1;
  height: calc(100vh - #{$logo-height});
  display: flex;

  @media (max-width: $breakpoint-m) {
    flex-direction: column;
    height: calc(100vh - #{$logo-height-mini});
  }

  &__slider {
    flex: 3;
    height: 100%;
    width: 100%;

    .bb-definition__container {
      font-family: 'Cormorant Garamond', serif;
      max-width: 750px;
      font-size: 4vh;
      padding: 0px 50px;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      @media (orientation: portrait) {
        font-size: 5vw;
      }

      .bb-definition {
        &__title {
          font-size: 7vh;
          font-weight: bold;

          @media (orientation: portrait) {
            font-size: 8vw;
          }
        }

        &__subtitle {
          font-style: italic;
        }

        &__entry {
          margin-top: 10px;
          border-top: 1px solid;
          padding: 10px 0px;
        }
      }
    }
  }

  &__image {
    flex: 2;
    background-size: cover;
    background-position: center;
  }

  img {
    object-fit: cover;
    max-width: 50%;
  }
}
</style>
