<template>
  <form id="bb-contactform" @submit.prevent="checkAndSubmit()">
    <div class="bb-contactform__container">
      <div class="bb-input__container">
        <input
          name="firstname"
          type="text"
          id="bb-input--firstname"
          placeholder="Vorname"
        />
      </div>
      <template v-for="input in inputs">
        <div
          :key="input.value.name_slug"
          class="bb-input__container"
          :class="{ 'bb-input__container--textarea': input.type == 'textarea' }"
        >
          <textarea
            v-if="input.value.type == 'textarea'"
            :id="'bb-input--' + input.id"
            :name="input.value.name_slug"
          ></textarea>
          <input
            v-else
            :name="input.value.name_slug"
            :type="input.value.type"
            :id="'bb-input--' + input.value.name_slug"
            :placeholder="getLabelOfField(input)"
            :required="input.value.required"
          />
          <label
            :for="'bb-input--' + input.value.name_slug"
            v-html="getLabelOfField(input)"
          ></label>
        </div>
      </template>
      <span>* Pflichtfelder</span>
      <div class="bb-submit__container">
        <input
          class="bb-button--primary"
          type="submit"
          value="Jetzt Projekt anfragen!"
          v-if="!submitted"
        />
        <div v-else class="bb-button--primary" :class="submitClass">
          {{ resultMessage }}
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    successMessage: {
      default: "Success!",
      type: String,
    },
    errorMessage: {
      default: "Error!",
      type: String,
    },
    inputs: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      submitted: false,
      submitError: false,
      resultMessage: "",
      phone: "",
      email: "",
      inputsOld: [
        {
          id: "firstname",
          label: "Vorname *",
          type: "text",
          required: false,
        },
        {
          id: "name",
          label: "Name *",
          type: "text",
          required: true,
        },
        {
          id: "email",
          label: "E-Mail *",
          type: "email",
          required: true,
        },
        {
          id: "phone",
          label: "Telefon",
          type: "tel",
          required: false,
        },
        {
          id: "message",
          label: "Nachricht",
          type: "textarea",
          required: false,
        },
        {
          id: "legal",
          label:
            "Ich habe die <a href=''>Datenschutzhinweise</a> gelesen, und stimme zu dass meine Angaben gespeichert und verarbeitet werden. *",
          type: "checkbox",
          required: true,
        },
      ],
    };
  },
  methods: {
    checkAndSubmit() {
      var formElement = document.getElementById("bb-contactform");

      this.axios
        .post("/submit/contact.php", new FormData(formElement), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          function (response) {
            console.log(response.data);
            this.submitted = true;
            this.resultMessage = this.successMessage;
          }.bind(this)
        )
        .catch((error) => {
          this.submitted = true;
          this.submitError = true;
          this.resultMessage = this.errorMessage;
          console.log(error);
        });
    },
    getLabelOfField(field) {
      var label = "";
      if (field.value.label == null || field.value.label == "")
        label = field.value.name;
      else label = field.value.label;

      if (field.value.required) label += " *";

      return label;
    },
  },
  computed: {
    submitClass() {
      if (this.submitted)
        return this.submitError
          ? "bb-highlight--error"
          : "bb-highlight--success";
      else return "";
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/variables";
@import "@/scss/basic";
@import "@/scss/app";

#bb-contactform {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: $content-width-m;
  width: 100%;

  .bb-contactform__container {
    flex: 1;
    display: flex;
    flex-direction: column;

    h2 {
      text-align: center;
    }

    textarea {
      height: 90%;
    }

    &:first-of-type {
      .bb-input__container:first-of-type {
        position: absolute;
        top: -50px;
      }
    }
  }

  #input-services {
    cursor: pointer;

    &::placeholder {
      color: $tertiary-color;
    }
  }

  .bb-submit__container {
    text-align: center;
  }

  input[type="submit"],
  textarea {
    width: 100%;
  }

  .bb-button--ghost {
    color: white;
    border-color: $tertiary-color;
    background-color: $tertiary-color;
  }
}
</style>