<template>
  <transition :name="type" @after-leave="$emit('close')">
    <div v-if="showing" class="bb-modal-mask">
      <div class="bb-modal-container" :class="typeClass">
        <div v-if="closeButton" class="bb-modal-default-button" @click.prevent="close">
          <span></span>
          <span></span>
        </div>
        <div class="bb-modal-header">
          <h3>
            <slot name="header"></slot>
          </h3>
        </div>

        <div class="bb-modal-body">
          <slot name="body"></slot>
        </div>

        <div class="bb-modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "middle",
    },
    closeButton: {
      type: Boolean,
      default: true
    },
    showingExternal: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      showingInternal: false,
    };
  },
  methods: {
    close: function () {
      this.showingInternal = false;
    },
  },
  computed: {
    typeClass: function () {
      return "bb-modal-type-" + this.type
    },
    showing() {
      return this.showingInternal && this.showingExternal
    }
  },
  mounted() {
    setTimeout(() => (this.showingInternal = true), 10);
  },
};
</script>

<style lang="scss">
@mixin full-width-modal($top, $bottom) {
  left: 0px;
  right: 0px;
  top: $top;
  bottom: $bottom;
  width: unset;
  transform: unset;
  border-radius: unset;
  max-width: 100%;
}

.bb-modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(5px);
}

.bb-modal-container {
  z-index: 11;
  width: 80%;
  max-width: 500px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.3s;

  @media (max-width: 500px) {
    @include full-width-modal(unset, 0px);
  }

  &.bb-modal-type- {
    &bottom {
      @include full-width-modal(unset, 0px);
    }

    &top {
      @include full-width-modal(0px, unset);

      .bb-modal-body {
        overflow-y: auto;
      }
    }
  }
}

.bb-modal-body {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bb-modal-default-button {
  position: absolute;
  width: 35px;
  height: 35px;
  right: 10px;
  top: 10px;
  cursor: pointer;

  span {
    display: block;
    height: 2px;
    width: 33px;
    background-color: black;
    position: absolute;
    top: 50%;

    &:first-of-type {
      transform: rotate(-45deg);
    }

    &:last-of-type {
      transform: rotate(45deg);
    }
  }
}
</style>
