<template>
  <svg
    class="bb-icon"
    viewBox="0 0 103 135"
    :width="width"
    :height="height"
  >
    <g transform="translate(-2,-69.999997)" id="layer1">
      <circle
        r="15"
        cy="137.5"
        cx="19.5"
        id="path833"
        style="
          fill: #ffffff;
          stroke: #000000;
          stroke-width: 10;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
      />
      <circle
        style="
          fill: #ffffff;
          stroke: #000000;
          stroke-width: 10;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        id="path833-0"
        cx="87.499992"
        cy="87.499992"
        r="15"
      />
      <circle
        r="15"
        cy="187.5"
        cx="87.499992"
        id="path833-0-8"
        style="
          fill: #ffffff;
          stroke: #000000;
          stroke-width: 10;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
      />
      <path
        sodipodi:nodetypes="cc"
        inkscape:original-d="M 32.505951,128.13393 74.461308,96.761905"
        inkscape:path-effect="#path-effect867"
        id="path865"
        d="M 32.505951,128.13393 74.461308,96.761905"
        style="
          fill: none;
          stroke: #000000;
          stroke-width: 10;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
      />
      <path
        style="
          fill: none;
          stroke: #000000;
          stroke-width: 10;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        d="M 73.818752,180.25683 31.863393,148.88481"
        id="path865-9"
        inkscape:path-effect="#path-effect886"
        inkscape:original-d="M 73.818752,180.25683 31.863393,148.88481"
        sodipodi:nodetypes="cc"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>