<template>
  <section class="bb-page__content" v-if="pageContent != '' && pageContent != null" v-html="pageContent"></section>
</template>
<script>
export default {
  props: {
    pageSlug: {
      type: String,
      require: true,
    },
  },
  computed: {
    pageContent() {
      var page = this.$store.state.pages.find(
        (page) => page.title_slug == this.pageSlug
      );

      return page == null ? '' : page.content;
    },
  },
};
</script>
