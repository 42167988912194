import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'

Vue.use(VueAxios, axios)
Vue.use(VueCookies)

Vue.prototype.$cmsUrl = process.env.VUE_APP_CMS_URL
Vue.prototype.$getThumbnail = function (imagePath, width, height = 0) {
  var thumbnailUrl = this.$cmsUrl + `/api/cockpit/image?src=${imagePath}&w=${width}&token=${process.env.VUE_APP_CMS_IMAGE_TOKEN}&o=1`

  if (height !== 0) {
    thumbnailUrl += '&h=' + height
  }

  return thumbnailUrl
}
Vue.prototype.$scrollToAnchor = (anchorName) => {
  document
    .getElementById(anchorName)
    .scrollIntoView({ behavior: "smooth" });
}

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#bb-app')
