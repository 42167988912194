<template lang="pug">
.bb-sharing__container(v-if="canShare")
  a(href="#", title="Share", @click.prevent="share")
    icon-share
    span.bb-share__label {{ label }}
  //modal-window(v-if="sharing")
    h2(slot="header") Artikel teilen
    div(slot="body").
      Teilen über Twitter & co.
    div(slot="footer") 
</template>

<script>
import IconShare from "./icons/IconShare.vue";
import ModalWindow from "./ModalWindow.vue";

export default {
  components: { IconShare, ModalWindow },
  props: {
    label: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    text: {
      type: String,
      require: true,
    },
    url: {
      type: String,
      default: window.location.href
    },
  },
  data() {
    return {
      sharing: false
    }
  },
  methods: {
    share() {
      this.sharing = true;

      if (this.canShare) {
        navigator
          .share({
            title: this.title,
            text: this.text,
            url: this.url,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      }
    },
  },
  computed: {
    canShare() {
      return navigator.share;
    },
  },
};
</script>
<style lang="scss">
.bb-sharing__container {
  margin: auto 0px;
  
  a {
    display: flex;
  }

  .bb-share__label {
    margin-left: 20px;
  }
}
</style>